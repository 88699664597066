import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryUrl) {
  const options = {
    dsn: environment.sentryUrl,
    normalizeDepth: 0,
    environment: environment.name,
    ignoreErrors: [
      'Non-Error exception captured',
      'NG0100: ExpressionChangedAfterItHasBeenCheckedError',
      'User denied Geolocation',
      'User denied geolocation prompt',
      'Cannot read properties of null (reading \'postMessage\')',
      'illegal access',
      /EmptyError: no elements in sequence/,
      'no elements in sequence',
      /Object captured as exception with keys:/,
    ],
  };

  if (environment.name === 'Test' || environment.name === 'Acceptatie') {
    Sentry.init({
      ...options,
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });
  } else {
    Sentry.init({ ...options });
  }
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}
