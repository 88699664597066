import { captureException } from '@sentry/angular-ivy';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    if (!environment.production) {
      console.error('%cError:', 'color: red', error); // Log error to console in red color
    }

    const exception = error?.error || error?.message || error?.originalError || error || '';
    let errorReason = 'Unknown reason';
    const chunkFailedMessage = /Loading chunk .+ failed|Failed to fetch dynamically imported module/;

    if (chunkFailedMessage.test(exception)) {
      this.reloadPage();

      return;
    }

    if (error && error instanceof HttpErrorResponse) {
      // Handle the HTTP errors without logging the error to Sentry
      return;
    } else if (error) {
      // Handle all errors and set as much information as possible in tags
      if (error.name === 'EmptyError') {
        errorReason = 'No data or empty array returned';
      } else if (error.name === '<unknown>' || error.title === 'unknown') {
        errorReason = `The <unknown> title usually means that there was no error message when the error was
                       generated or is not caught and handled by the error handling system`;
      }

      this.captureSentryException(exception, errorReason);
    }
  }

  public reloadPage(): void {
    window.location.reload();
  }

  public captureSentryException(error: any, errorReason: string): void {
    captureException(error, {
      tags: {
        headers: error.headers,
        message: error.message,
        name: error.name,
        reason: errorReason,
      },
    });
  }
}
